import React from 'react';
import cn from 'classnames';
import Numberinput from './NumberinputOld';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Plate from './PlateOld';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import { ReactComponent as Auto3 } from '../img/samples/car.svg';
import { ReactComponent as Cargo2 } from '../img/samples/cargo.svg';

export default ({ pattern, state, name, shortname, region, setRegion, description, sample, onclick, number, setNumber, nextStage }) => {

    const styles = {
        wrapperMobile: {
            margin: '0 0 1em 0',
        },
        wrapper: {
            margin: '2em',
        }
    };

    const dispatch = (name) => (wrapper) => {
        const dispatcher = {
            auto_3: <Auto3 style={wrapper} />,
            cargo_2: <Cargo2 style={wrapper} />,
        };
        return dispatcher[name];

    };

    const clickHandle = () => {
        // nextStage();
        return onclick(pattern);
    }

    const isActive = pattern === state;

    const currentClass = cn({
        'switch': true,
        'active': isActive,
    });

    const inputArea = isActive
        ? <>
        {/* {dispatch(sample)(styles.wrapperMobile)} */}
            <Plate ptrn={pattern} region={region} number={number} />
            <Numberinput pattern={pattern} value={number} updateNumber={setNumber} region={region} updateRegion={setRegion} nextStage={nextStage} />
        </>
        : <><Hidden smUp>
            {dispatch(sample)(styles.wrapperMobile)}
            <Button variant="outlined" color="primary" size="small">Дубликат{<br />}на{<br />}{shortname}</Button>
        </Hidden>
            <Hidden smDown>
                {dispatch(sample)(styles.wrapper)}
                <Button variant="outlined" color="primary" size="large">Заказать дубликат</Button>
                <Typography variant='h5' className='headline'>
                    {name}
                </Typography>
                <span className='desc'>{description}</span>
            </Hidden>
        </>;


    return (
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div onClick={clickHandle} className={currentClass}>
                {inputArea}
            </div>
        </Grid>
    )
}