import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Plateregion from './Plateregion';
import { calculate } from '../prices';

const styles = {
  button: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  amount: {
    fontSize: '2.0em',
    fontWeight: 'bold'
  },
  description: {
    fontSize: '1.4em'
  }
};

const selectButton = (type, onSelectHandle, withFlag, plateValue, pattern, userdata, setUserData) => {
  const changeHandle = (value) => () => {
    const type = value ? 1 : 2;
    setUserData({
      ...userdata,
      totalCost: calculate(userdata.qty, type, userdata.deliveryCost)
    });
    onSelectHandle(value);
  };
  const currentColor = withFlag && type === 1 || !withFlag && type === 2 ? 'primary' : '';

  return (
    <Button color={currentColor} onClick={changeHandle(type === 1)}>
        <Plateregion number={plateValue} ptrn={pattern} type={type} />
    </Button>
  );
}

const textButton = (type, onSelectHandle, withFlag, plateValue, pattern, userdata, setUserData) => {
  const changeHandle = (value) => () => {
    const type = value ? 1 : 2;
    setUserData({
      ...userdata,
      totalCost: calculate(userdata.qty, type, userdata.deliveryCost)
    });
    onSelectHandle(value);
  };
  console.log(type);
  const currentColor = withFlag && type === 1 || !withFlag && type === 2 ? 'primary' : '';
  const text = type === 1 ? 'С флагом' : 'Без флага';

  return (
    <Button color={currentColor} onClick={changeHandle(type === 1)}>
      {text}
    </Button>
  );
}

export default function GroupedButtons({ onSelect, withFlag, plateValue, pattern, userdata, setUserData }) {

  return (
    <>
    <ButtonGroup fullWidth variant="outlined" >
      { selectButton(1, onSelect, withFlag, plateValue, pattern, userdata, setUserData) }
      { selectButton(2, onSelect, withFlag, plateValue, pattern, userdata, setUserData) }
    </ButtonGroup>
    <ButtonGroup fullWidth variant="contained" >
      { textButton(1, onSelect, withFlag, plateValue, pattern, userdata, setUserData) }
      { textButton(2, onSelect, withFlag, plateValue, pattern, userdata, setUserData) }
    </ButtonGroup>
    </>
  );
}