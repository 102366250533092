import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dateinput from './Dateinput';
import Delivery from './Delivery';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function CenteredTabs({ setUserData, userdata }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setUserData({
        ...userdata,
        pickupLocation: 'Самовывоз',
      });
    }
  };

  const handleDeliveryClick = () => {
    setUserData({
      ...userdata,
      pickupLocation: 'Доставка',
    });
  }

  const handleselfClick = () => {
    setUserData({
      ...userdata,
      pickupLocation: 'Самовывоз',
    });
  }

  const delivery = (
    <>
      <br />
      <Typography variant='h5'>Условия доставки:</Typography><br />
      <p>Стоимость доставки в пределах КАД от 300 руб.</p>
      <p>Зависит от района и времени. Уточните стоимость доставки у сотрудника, который свяжется с Вами для подтверждения заказа.</p>
      {/* <Dateinput onSelect={setUserData} userdata={userdata} /> */}
      {/* <Delivery onSelect={setUserData} userdata={userdata} /> */}
    </>
  );

  const selfPickup = (
    <>
      <br />
      <Typography variant='h5'>Автоцентр «Балканский»</Typography><br />
      <p>Ст. м. «Купчино», Малая Балканская 59/1</p>
      <p>тел.: +7 (921) 960-7-690. (9:00 - 21:00)</p>

    </>
  );

  return (
    <>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Самовывоз" onClick={handleselfClick}/>
          <Tab label="Доставка" onClick={handleDeliveryClick} />
        </Tabs>
      </Paper>
      <div>
        {value === 1 ? delivery : selfPickup}
      </div>
    </>
  );
}