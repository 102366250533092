const prices = {
  1: 1100,
  2: 2000
};
const pricesWoFlag = {
  1: 1200,
  2: 2400
};
const discountPercent = 0;

const getPrice = qty => prices[qty];

const getDiscount = () => `- ${discountPercent} %`;

const getDiscountAmount = qty => Math.floor(prices[qty] * discountPercent / 100); 

const calculate = (qty, type, delivery) => {
  return type === 1
    ? Math.floor(prices[qty] - getDiscountAmount(qty)) + delivery
    : Math.floor(pricesWoFlag[qty] - getDiscountAmount(qty)) + delivery;
}

export { prices, calculate, getPrice, getDiscount, getDiscountAmount };