import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

function TextMaskPhone(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+','7',' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/,'-', /\d/, /\d/]}
      placeholderChar={'\u005F'}
      keepCharPositions={true}
      // showMask
    />
  );
}

TextMaskPhone.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const styles = {
  root: {
    width: '100%',
  }
};

export default ({ onSelect, userdata }) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    textmask: '',
  });

  const handleChange = name => event => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
    onSelect({
      ...userdata,
      phone: event.target.value
    });
  };

  const handleChangeName = event => {
    onSelect({
      ...userdata,
      name: event.target.value
    });
  };

  return (
    <div className={classes.container}>
      <TextField   
        value={userdata.name}
        onChange={handleChangeName}
        label="Контактное лицо:"
        style={{ margin: 8 }}
        id="name-input"
        fullWidth
        margin="normal"
      />
      <FormControl className={classes.formControl} style={styles.root}>
        <InputLabel htmlFor="formatted-text-mask-input">Контактный телефон:</InputLabel>
        <Input
          type='tel'
          variant="outlined"
          value={userdata.phone}
          onChange={handleChange('textmask')}
          id="formatted-text-mask-input"
          inputComponent={TextMaskPhone}
        />
      </FormControl>
    </div>
  );
}