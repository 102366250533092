import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { calculate } from '../prices';

const styles = {
  button: {
    display: 'flex',
    flexDirection: 'column'
  },
  amount: {
    fontSize: '2.0em',
    fontWeight: 'bold'
  },
  description: {
    fontSize: '1.4em'
  }
};

export default function GroupedButtons({ onSelect, userdata, withFlag }) {
  const type = withFlag ? 1 : 2;
  const changeHandle = (value) => (event) => {
    onSelect({
        ...userdata,
        qty: value,
        totalCost: calculate(value, type, userdata.deliveryCost)
    });
  }
  

  const buttonWrapper = (value) => {
    return value == userdata.qty ? (
      <Button color="primary" onClick={changeHandle(value)}>
        <div style={styles.button}>
          <span style={styles.amount}>{'\u2713'}  {value} шт</span>
          {/* <span style={styles.description}>за {prices[value]} {'\u20BD'}</span> */}
        </div>
        </Button>
    ) : (
      <Button onClick={changeHandle(value)}>
        <div style={styles.button}>
        <span style={styles.amount}>{value} шт</span>
          {/* <span style={styles.description}>за {prices[value]} {'\u20BD'}</span> */}
        </div>
      </Button>
    );
  }
  return (
    <ButtonGroup fullWidth variant="outlined">
      {buttonWrapper(1)}{buttonWrapper(2)}
     </ButtonGroup>
  );
}