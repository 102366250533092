import React, { useState } from 'react';
import './App.css';
import Stage0 from './stages/StageStartOld';
import Stage2 from './stages/StageOrder';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { calculate } from './prices';

function App() {

  const [number, setNumber] = useState('');
  const [region, setRegion] = useState('');
  const [withFlag, setWithFlag] = useState(true);

  const [pattern, setPattern] = useState('ZNNNzzRRR/');
  const [stage, setStage] = useState(0);

  const [userdata, setUserData] = useState(
    {
      name: '',
      phone: '',
      qty: '1',
      pickupLocation: 'Самовывоз',
      deliveryCost: 0,
      pickupDate: new Date(),
      totalCost: calculate(1, 1, 0),
    }
  );

  const nextStage = () => setStage(stage + 1);
  const prevStage = () => stage !== 0 ? setStage(stage - 1) : setStage(0);

  const isReadyToCheckout = (obj) => {
    const nulledValues = Object.keys(obj).filter(key => obj[key] === '');
    return nulledValues.length == 0;
  }

  const theme = createMuiTheme({
    palette: {
      type: 'dark', // Switching the dark mode on is a single property value change.
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#c6ff00',
        light: '#ffffff',
        dark: '#c6ff00',
        contrastText: '#141711',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: '#ffffff',
        main: '#ffffff',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffcc00',
      },
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {stage === 0 && <Stage0 number={number} pattern={pattern} setNumber={setNumber} region={region} setRegion={setRegion} setPattern={setPattern} nextStage={nextStage} />}
        {stage === 1 &&
          <Stage2
            number={number}
            pattern={pattern}
            nextStage={nextStage}
            region={region}
            prevStage={prevStage}
            userdata={userdata}
            setUserData={setUserData}
            isReadyToCheckout={isReadyToCheckout}
            setWithFlag={setWithFlag}
            withFlag={withFlag}
          />}
      </ThemeProvider>
    </div>
  );
}

export default App;
