import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { calculate } from '../prices';



const suggestions = [
  { name:'Самовывоз', time: '10:00 - 21:00', extra: 0,},
  { name:'Девяткино', time: '10:00 - 21:00', extra: 300,},
  { name:'Гражданский проспект', time: '10:00 - 21:00', extra: 300,},
  { name:'Академическая', time: '10:00 - 21:00', extra: 300,},
  { name:'Политехническая', time: '10:00 - 21:00', extra: 300,},
  { name:'Площадь Мужества', time: '10:00 - 21:00', extra: 300,},
  { name:'Лесная', time: '10:00 - 21:00', extra: 300,},
  { name:'Выборгская', time: '10:00 - 21:00', extra: 300,},
  { name:'Площадь Ленина', time: '10:00 - 21:00', extra: 300,},
  { name:'Чернышевская', time: '10:00 - 21:00', extra: 300,},
  { name:'Площадь Восстания', time: '10:00 - 21:00', extra: 300,},
  { name:'Владимирская', time: '10:00 - 21:00', extra: 300,},
  { name:'Пушкинская', time: '10:00 - 21:00', extra: 300,},
  { name:'Технологический институт', time: '10:00 - 21:00', extra: 300,},
  { name:'Балтийская', time: '10:00 - 21:00', extra: 300,},
  { name:'Нарвская', time: '10:00 - 21:00', extra: 300,},
  { name:'Кировский завод', time: '10:00 - 21:00', extra: 300,},
  { name:'Автово', time: '10:00 - 21:00', extra: 300,},
  { name:'Ленинский проспект', time: '10:00 - 21:00', extra: 300,},
  { name:'Проспект Ветеранов', time: '10:00 - 21:00', extra: 300,},
  { name:'Парнас', time: '10:00 - 21:00', extra: 300,},
  { name:'Проспект Просвещения', time: '10:00 - 21:00', extra: 300,},
  { name:'Озерки', time: '10:00 - 21:00', extra: 300,},
  { name:'Удельная', time: '10:00 - 21:00', extra: 300,},
  { name:'Пионерская', time: '10:00 - 21:00', extra: 300,},
  { name:'Чёрная речка', time: '10:00 - 21:00', extra: 300,},
  { name:'Петроградская', time: '10:00 - 21:00', extra: 300,},
  { name:'Горьковская', time: '10:00 - 21:00', extra: 300,},
  { name:'Невский проспект', time: '10:00 - 21:00', extra: 300,},
  { name:'Сенная площадь', time: '10:00 - 21:00', extra: 300,},
  { name:'Технологический институт', time: '10:00 - 21:00', extra: 300,},
  { name:'Фрунзенская', time: '10:00 - 21:00', extra: 300,},
  { name:'Московские ворота', time: '10:00 - 21:00', extra: 300,},
  { name:'Электросила', time: '10:00 - 21:00', extra: 300,},
  { name:'Парк Победы', time: '10:00 - 21:00', extra: 300,},
  { name:'Московская', time: '10:00 - 21:00', extra: 200,},
  { name:'Звёздная', time: '10:00 - 21:00', extra: 0,},
  { name:'Купчино', time: '10:00 - 21:00', extra: 0,},
  { name:'Беговая', time: '10:00 - 21:00', extra: 300,},
  { name:'Новокрестовская', time: '10:00 - 21:00', extra: 300,},
  { name:'Приморская', time: '10:00 - 21:00', extra: 300,},
  { name:'Василеостровская', time: '10:00 - 21:00', extra: 300,},
  { name:'Гостиный двор', time: '10:00 - 21:00', extra: 300,},
  { name:'Маяковская', time: '10:00 - 21:00', extra: 300,},
  { name:'Площадь Александра Невского', time: '10:00 - 21:00', extra: 300,},
  { name:'Елизаровская', time: '10:00 - 21:00', extra: 200,},
  { name:'Ломоносовская', time: '10:00 - 21:00', extra: 200,},
  { name:'Пролетарская', time: '10:00 - 21:00', extra: 300,},
  { name:'Обухово', time: '10:00 - 21:00', extra: 300,},
  { name:'Рыбацкое', time: '10:00 - 21:00', extra: 300,},
  { name:'Спасская', time: '10:00 - 21:00', extra: 300,},
  { name:'Достоевская', time: '10:00 - 21:00', extra: 300,},
  { name:'Лиговский проспект', time: '10:00 - 21:00', extra: 300,},
  { name:'Площадь Александра Невского', time: '10:00 - 21:00', extra: 300,},
  { name:'Новочеркасская', time: '10:00 - 21:00', extra: 300,},
  { name:'Ладожская', time: '10:00 - 21:00', extra: 300,},
  { name:'Проспект Большевиков', time: '10:00 - 21:00', extra: 300,},
  { name:'Улица Дыбенко', time: '10:00 - 21:00', extra: 300,},
  { name:'Комендантский проспект', time: '10:00 - 21:00', extra: 300,},
  { name:'Старая Деревня', time: '10:00 - 21:00', extra: 300,},
  { name:'Крестовский остров', time: '10:00 - 21:00', extra: 300,},
  { name:'Чкаловская', time: '10:00 - 21:00', extra: 300,},
  { name:'Спортивная', time: '10:00 - 21:00', extra: 300,},
  { name:'Адмиралтейская', time: '10:00 - 21:00', extra: 300,},
  { name:'Садовая', time: '10:00 - 21:00', extra: 300,},
  { name:'Звенигородская', time: '10:00 - 21:00', extra: 300,},
  { name:'Обводный канал', time: '10:00 - 21:00', extra: 200,},
  { name:'Волковская', time: '10:00 - 21:00', extra: 200,},
  { name:'Бухарестская', time: '10:00 - 21:00', extra: 0,},
  { name:'Международная', time: '10:00 - 21:00', extra: 0,},
  { name:'Проспект Славы', time: '10:00 - 21:00', extra: 0,},
  { name:'Дунайская', time: '10:00 - 21:00', extra: 0,},
  { name:'Шушары', time: '10:00 - 21:00', extra: 0,},
];

function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;
  
    return (
      <TextField
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}
      />
    );
  }
  
  renderInput.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    InputProps: PropTypes.object,
  };
  
  function renderSuggestion(suggestionProps) {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;  
    const isSelected = (selectedItem || '').toLowerCase().indexOf(suggestion.name) > 0;
  
    return (
      <MenuItem
        {...itemProps}
        key={suggestion.name}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 600 : 400,
          color: isSelected ? '#00ef00' : 'inherit',
        }}
      >
        {suggestion.name} (+{suggestion.extra} Руб.)
      </MenuItem>
    );
  }
  
  renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
    index: PropTypes.number.isRequired,
    itemProps: PropTypes.object.isRequired,
    selectedItem: PropTypes.string.isRequired,
    suggestion: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
  };
  
  function getSuggestions(value, { showEmpty = false } = {}) {
    const inputValue = value.toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 && !showEmpty
      ? []
      : suggestions.filter(suggestion => {
          return suggestion.name.toLowerCase().indexOf(inputValue) !== -1;
        });
  }

  const useStyles = makeStyles(theme => ({
    root: {
        color: '#fff',
      flexGrow: 1,
      // height: 250,
    },
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    inputRoot: {
      flexWrap: 'wrap',
    },
    inputInput: {
      width: 'auto',
      flexGrow: 1,
    },
    divider: {
      height: theme.spacing(1),
    },
  }));
  
  
  export default function IntegrationDownshift({ onSelect, userdata }) {
    const classes = useStyles();

    const handleChange = (selectedItem) => {
      const currentMetro = suggestions.filter(metro => metro.name === selectedItem)[0];
      onSelect({
        ...userdata,
        pickupLocation: selectedItem,
        deliveryCost: currentMetro.extra,
        totalCost: calculate(userdata.qty, currentMetro.extra),
      });
    }
    return (
        
      <div className={classes.root}>
        <Downshift id="downshift-simple" onChange={handleChange}>
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
          }) => {
            const { onBlur, onFocus, ...inputProps } = getInputProps({
              placeholder: 'Начните вводить название станции метро',
            });

            return (
              <div className={classes.container}>
                
                {renderInput({
                  fullWidth: true,
                  classes,
                  label: 'Станция метро',
                  InputLabelProps: getLabelProps({ shrink: true }),
                  InputProps: { onBlur, onFocus },
                  inputProps,
                })}
  
                <div {...getMenuProps()}>
                  {isOpen ? (
                    <Paper className={classes.paper} square>
                      {getSuggestions(inputValue).map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({ item: suggestion.name }),
                          highlightedIndex,
                          selectedItem,
                        }),
                      )}
                    </Paper>
                  ) : null}
                </div>
              </div>
            );
          }}
        </Downshift>
      </div>
    );
  }