import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { getDiscount, getDiscountAmount } from '../prices';



const style = {
  root: {
    width: '100%',
    marginBottom: '1em'
  },
  dl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px dotted #fff',
  },
  dt: {},
  dd: {
    fontWeight: 'bold',
    color: '#edf966'
  },
  priceHeadline: {
    fontSize: '2.0em',
    // float: 'left',
    // textAlign: 'left',
    fontWeight: 'normal',
  },
  price: {
    fontSize: '4.0em',
    // float: 'left',
    // textAlign: 'left',
    fontWeight: 'bold',
  },
  discount: {
    fontSize: '1.6em',
    // float: 'left',
    // textAlign: 'left',
    fontWeight: 'normal',
    color: '#edf966',
  },
}

export default ({ userdata }) => {
  const items = Object.keys(userdata).filter(key => key !== 'totalCost').map(
    line => {
      const name = {
        qty: 'Количество:',
        name: 'Получатель:',
        phone: 'Телефон:',
        pickupDate: 'Дата доставки:',
        pickupLocation: 'Где забрать:',
        deliveryCost: 'Доставка:'
      };

      const value = {
        qty: `${userdata[line]} шт.`,
        name: userdata[line],
        phone: userdata[line],
        pickupDate: moment(new Date(userdata[line])).locale('ru').format('LL'),
        pickupLocation: userdata[line],
        deliveryCost: userdata[line] === 0 ? 'Бесплатно!' : `${userdata[line]} Руб.`
      };

    const currentLine = (
      userdata[line] !== null && <dl style={style.dl} key={line}>
        <dt>{`${name[line]}`}</dt>
        <dd style={style.dd}>{`${value[line]}`}</dd>
      </dl>
    );
    return currentLine;
  }
  );

  const platesAmount = userdata.totalCost;
  const sum = platesAmount;
  const discount = getDiscountAmount(userdata.qty);

  return (
    <div style={style.root}>
      <span style={style.priceHeadline}>Сумма к оплате:</span><br />
      <span style={style.price}>{sum.toLocaleString('ru-RU')} &#8381;</span>
    </div>
    
  );
}