import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Plate from '../compoments/Plate';
import Buttons from '../compoments/Buttons';
import Buttonsregion from '../compoments/Buttonsregion';
import Typography from '@material-ui/core/Typography';
import Phoneinput from '../compoments/Phoneinput';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Pickupmethod from '../compoments/Pickupmethod';
import SummaryPrice from '../compoments/SummaryPrice';
import CircularProgress from '@material-ui/core/CircularProgress';
import fetch from 'unfetch';



const useStyles = makeStyles({
    active: {
        background: 'linear-gradient(45deg, #55a323 30%, #1daa04 90%)',
        border: 0,
        borderRadius: 3,
        // boxShadow: '0 3px 2px 2px rgba(255, 255, 255, .1)',
        color: '#fff800',
        height: 48,
        padding: '0 30px',
        fontWeight: 'bold'
    },
    inactive: {
        background: 'linear-gradient(45deg, #193500 30%, #254c03 90%)',
        border: 0,
        borderRadius: 3,
        // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
    },
    processing: {
        background: 'linear-gradient(45deg, #193500 30%, #254c03 90%)',
        border: 0,
        borderRadius: 3,
        color: 'white',
        height: 48,
        padding: '0 30px',
        margin: '30px 0',
        width: '100%',
    },
    complete: {
        border: 0,
        borderRadius: 3,
        color: '#000000',
        height: 48,
        padding: '0 30px',
        margin: '30px 0',
        width: '100%',
    },
});

export default ({ number, pattern, region, userdata, setUserData, nextStage, prevStage, isReadyToCheckout, setWithFlag, withFlag }) => {
    const classes = useStyles();
    const [isReady, setReady] = useState(false);
    const [inProcess, setProcessStatus] = useState(false);

    const putOrderRequest = () => {
        const putQuery = `/putorder/${userdata.name}/${userdata.phone}/${userdata.pickupDate}/${userdata.pickupLocation}/${userdata.deliveryCost}/${number}${region}/${userdata.qty}/${userdata.totalCost}/${withFlag}`;
        fetch(putQuery)
            .then(r => r.json())
            .then((r) => {
                setProcessStatus(true);
                return r;
            })
            .then(data => {
                if (data.status === 'success') {
                    setTimeout(() => {
                        setReady(true);
                        setProcessStatus(false);
                        // Put metrik event
                        const params = { total: userdata.totalCost };
                        window.ym(56664751, 'reachGoal', 'REACT', params);
                    }, 1500);
                    return;
                }
                setReady(false);
                setProcessStatus(false);
                return;
            })
            .catch(e => {
                console.log(e);
            });
    }


    const styles = {
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: '5vh 0',
            alignItems: 'center',
            justifyContent: 'center',
        },
        container: {
            textAlign: 'left',
        },
        result: {
            background: 'rgba(237,249,102,.1)',
            padding: '2vh 2vw',
            textAlign: 'left',
        },
        buttonblock: {
            textAlign: 'center',
            margin: '2em 0'
        },
        spinner: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: '2em'
        }
    };
    const back = () => {
        return prevStage();
    }
    const numberLength = (pattern) => {
        return pattern.split('').filter(
            char => char !== 'R' && char !== '/'
        ).length;
    }

    const plateValue = region.length !== 0
        ? `${number.slice(0, numberLength(pattern))}${region}`
        : number;


    const currentClass = isReadyToCheckout(userdata) ? classes.active : classes.inactive;

    const disabled = isReadyToCheckout(userdata) ? false : true;
    const processCheckout = <Button variant="outlined" color="primary" className={currentClass} fullWidth={true} disabled={disabled} onClick={nextStage} size='large' style={styles.buttonblock}>Далее {'\u2192'}</Button>
    const Orderbutton = (
        <Button variant="outlined" color="primary" fullWidth={true} style={styles.buttonblock} className={currentClass} onClick={putOrderRequest} disabled={disabled}>
            Оформить заказ {'\u2192'}
        </Button>
    );


    return (
        <Container>
            <Grid container spacing={3} className='bp3-dark'>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={styles.root}>
                            <Button variant="outlined" color="primary" onClick={back} style={styles.buttonblock}>&larr; Изменить номер</Button>
                            <Plate number={plateValue} ptrn={pattern} withFlag={withFlag} />

                        </Grid>
                    </Grid>

                    <Grid container spacing={5}>
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={12} style={styles.container}>
                            <Typography variant='h5'>Количество:</Typography>
                            <br />
                            <Buttons userdata={userdata} onSelect={setUserData} withFlag={withFlag} />
                            <br />
                            <Typography variant='h5' style={{ margin: '1em 0 .5em 0' }}>С флагом или без?</Typography>
                            <br />
                            <Buttonsregion withFlag={withFlag} onSelect={setWithFlag} setUserData={setUserData} userdata={userdata} plateValue={plateValue} pattern={pattern} />
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={12} style={styles.container}>

                            <Typography variant='h5' style={{ margin: '0 0 1em 0' }}>Доставка:</Typography>

                            <Pickupmethod setUserData={setUserData} userdata={userdata} />
                            <br />
                            <SummaryPrice userdata={userdata} />

                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={12} style={styles.container}>
                            <Typography variant='h5'>Кто заберет?</Typography>
                            <Phoneinput onSelect={setUserData} userdata={userdata} />
                            <br />
                            {
                                inProcess &&
                                <div style={styles.spinner}>
                                    <CircularProgress color="secondary" />
                                    <Typography variant='body2' style={{paddingTop: '2em'}}>Размещение заказа, пожалуйста подождите, это не займет много времени...</Typography>
                                </div>
                            }
                            {!inProcess && isReady && <Typography variant='body1' spacing={5}>{userdata.name}, Ваш заказ успешно оформлен.<br />Ожидайте звонка нашего специалиста по телефону:<br /><b>{userdata.phone}</b>.</Typography>}
                            {!isReady && Orderbutton}
                            <br />
                            {!inProcess && isReady && <Typography variant='h4' style={styles.headline}>Поздравляем!</Typography>}
                            {!inProcess && !isReady &&
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: '.8em', padding: '3em 2em 2em 2em', textAlign: 'center' }} spacing={5}>Нажимая кнопку "Оформить заказ" вы соглашаетесь с политикой конфиденциальности и даете согласие на хранение и обработку персональной информации.</Typography>
                                </div>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} style={{ padding: '2em 0', borderTop: '1px dashed #fff', marginTop: '2em', textAlign: 'center' }}>
                        <Typography variant='body2' style={{textAlign: 'center' }}>&#10102; Выдача готовых номерных знаков осуществляется <b style={styles.headline}>только по предъявлении СТС</b> на автомобиль владельцем или доверенным лицом (необходима доверенность).<br />&#10103; Также при получении необходим документ, <b style={styles.headline}>удостоверяющий личность</b> получателя (водительское удостоверение или паспорт).</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}