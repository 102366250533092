import React from 'react';
import Symbol from './Symbol';
import plate from '../img/plates/plate_3.svg'

export default ({ string, region, pattern, width }) => {
  let state = {
    isMask: false,
    isRegion: null
  };

  const currentWidth = width;

  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    num: {
      float: 'left',
      marginBottom: currentWidth * 0.01,
      marginLeft: currentWidth * 0.06,
      display: 'flex',
      flexDirection: 'row',
      width: currentWidth * 0.62,
      height: currentWidth * 0.1574, //width / 4
      justifyContent: 'space-around',
      // border: '1px #bc0000 solid'
    },
    reg: {
      marginTop: 0,
      marginRight: currentWidth * 0.045,
      display: 'flex',
      flexDirection: 'row',
      width: currentWidth * 0.21,
      height: currentWidth * 0.1574, //width / 4
      justifyContent: 'center',
      // border: '1px #bc0000 solid'
    },
    plate: {
      background: `url(${plate})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      width: currentWidth,
      height: currentWidth / 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  };

  const numberArr = string.split('');
  const regionArr = region.split('');
  
  const patternArr = pattern.split('').filter(el => el !== '/');
  const numberPattern = patternArr.slice(0, patternArr.indexOf('R'));
  const regionPattern = patternArr.filter(el => el === 'R');

  const makeImgNum = numberPattern.reduce((acc, symb, key) => {
    if (!numberArr[key]) {
      state.isMask = true;
    }

    const currentSymbol = state.isMask ? symb : numberArr[key];


    const result = 
      [...acc, <Symbol char={currentSymbol} key={key} isRegion={state.isRegion} width={currentWidth} hidden={state.isMask} />];

    return result;
  }, []);

  const makeImgReg = regionPattern.reduce((acc, symb, key) => {
    if (!regionArr[key]) {
      state.isMask = true;
    }

    const currentSymbol = state.isMask ? symb : regionArr[key];


    const result = 
      [...acc, <Symbol char={currentSymbol} key={key} isRegion={true} width={currentWidth} hidden={state.isMask} />];

    return result;
  }, []);




  return (
    <div style={styles.plate}>
      <div style={styles.root}>
        <div id='number' style={styles.num}>{[...makeImgNum]}</div>
        <div id='region' style={styles.reg}>{[...makeImgReg]}</div>
      </div>
    </div>
  );
}