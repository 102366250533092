import React, { useState, useRef } from 'react';
import Legend from './Legend';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = {
    wrapper: {
        margin: '1em 0 1em 0',
    }
};

export default ({ value, region, pattern, updateNumber, updateRegion, nextStage }) => {
    const position = value === '' ?
        pattern.split('')[0] : pattern.split('').slice(0, value.split('').length + 1).pop();

    const numberLength = (pattern) => {
        return pattern.split('').filter(
            char => char !== 'R' && char !== '/'
        ).length;
    }

    const regionLength = (pattern) => {
        return pattern.split('').filter(
            char => char === 'R' && char !== '/'
        ).length;
    }










    const inputRef = useRef();

    const focuseIt = () => {
        const timeout = setTimeout(() => {
            inputRef.current.focus();
        }, 100);
    };






    const validateNumber = (input) => {
        const alfabet = new Set([
            'а', 'a',
            'в', 'b',
            'е', 'e',
            'к', 'k',
            'м', 'm',
            'н', 'h',
            'о', 'o',
            'р', 'p',
            'с', 'c',
            'т', 't',
            'у', 'y',
            'х', 'x'
        ]);
        const numbers = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);

        return pattern.split('').reduce((result, currentPatternSymbol, key) => {
            const inputArr = input.split('');
            const symbol = `${inputArr[key]}`.toLowerCase();

            if (currentPatternSymbol === 'Z' && alfabet.has(symbol)) return [...result.split(''), symbol].join('');
            if (currentPatternSymbol === 'z' && alfabet.has(symbol)) return [...result.split(''), symbol].join('');
            if (currentPatternSymbol === 'N' && numbers.has(symbol)) return [...result.split(''), symbol].join('');
            if (currentPatternSymbol === 'R' && numbers.has(symbol)) return [...result.split(''), symbol].join('');
            return result.slice(0, numberLength(pattern));
        }, '');
    }

    const validateRegion = (input) => {
        const numbers = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);
        return input.split('').filter(
            char => numbers.has(char)
        ).join('').slice(0, regionLength(pattern));
    }



    const numberIsReady = value.length === numberLength(pattern);
    // const regionIsReady = region.length === regionLength(pattern);
    const regionIsReady = regionLength(pattern) === 2
        ? region.length === regionLength(pattern)
        : region.length === 2 || region.length === 3;

    const canProceed = numberIsReady && regionIsReady;




    const onChangeHandleNumber = (event) => {
        event.preventDefault();
        const numberValue = event.target.value;

        updateNumber(validateNumber(numberValue));
        if (numberValue.length === numberLength(pattern)) focuseIt();
    };

    const onChangeHandleRegion = (event) => {
        event.preventDefault();
        const regionValue = event.target.value;

        updateRegion(validateRegion(regionValue));
        if (numberIsReady) focuseIt();
    };






    const getNumberInputMask = (pattern) => {
        return pattern.split('').slice(0, pattern.indexOf('R')).filter(
            char => char !== 'R' || char !== '/'
        ).map(
            char => char === 'N' ? '0' : 'x'
        ).join('');
    }

    const getRegionInputMask = (pattern) => {
        return pattern.split('').filter(char => char === 'R').map(char => '0').join('');
    }





    const legend = <Legend currentState={position} />;



    return (
        <Grid item alignItems='center' alignContent='center' justify='center' style={styles.wrapper}>
            {legend}
            <Grid container spacing={1}>

                <Grid item xl={7} lg={7} md={7} sm={7} xs={7}>
                    <TextField
                        id="outlined-helperText"
                        label="Знак:"
                        placeholder={getNumberInputMask(pattern)}
                        variant="outlined"
                        value={value}
                        onChange={onChangeHandleNumber}
                        autoFocus
                        size='medium'
                        fullWidth
                    />
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                    <TextField
                        id="outlined-helperText"
                        placeholder={getRegionInputMask(pattern)}
                        variant="outlined"
                        value={region}
                        onChange={onChangeHandleRegion}
                        autoFocus={false}
                        inputRef={inputRef}
                        disabled={!numberIsReady}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">rus</InputAdornment>,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ padding: '1em 0' }}
                        disabled={!canProceed}
                        onClick={nextStage}
                        fullWidth>
                        {canProceed ? 'Продолжить' : 'Введите данные'}
                    </Button>
                </Grid>
            </Grid>

            {/* {legend} */}
        </Grid>

    );
}