import React from 'react';
import Symbolregion from './Symbolregion';
import reg1 from '../img/plates/reg_1.svg'
import reg2 from '../img/plates/reg_2.svg'

export default ({ string, pattern, width, type }) => {
  let state = {
    isMask: false,
    isRegion: null
  };

  const currentWidth = width;

  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    num: {
      float: 'left',
      marginBottom: currentWidth * 0.01,
      marginLeft: currentWidth * 0.06,
      display: 'flex',
      flexDirection: 'row',
      width: currentWidth * 0.62,
      height: currentWidth * 0.1574, //width / 4
      justifyContent: 'space-around',
      // border: '1px #bc0000 solid'
    },
    reg: {
      marginTop: 0,
      // paddingRight: currentWidth * 0.05,
      display: 'flex',
      flexDirection: 'row',
      // width: currentWidth * 0.4,
      // height: currentWidth * 0.2, //width / 4
      justifyContent: 'center',
    },

    plate: {
      background: type === 1 ? `url(${reg1})` : `url(${reg2})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      width: currentWidth,
      height: currentWidth,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
  },
  };

  const stringArr = string.split('');
  const patternArr = pattern.split('').filter(el => el !== '/');

  const makeImg = patternArr.reduce((acc, symb, key) => {
    if (!stringArr[key]) {
      state.isMask = true;
    }
    if (symb === 'R') {
      state.isRegion = true
    }

    const currentSymbol = state.isMask ? symb : stringArr[key];
    const part = state.isRegion ? 'reg' : 'num';

    const result = state.isRegion && state.isMask 
      ? { ...acc }
      : { ...acc, [part]: [...acc[part], <Symbolregion char={currentSymbol} key={key} isRegion={state.isRegion} width={currentWidth} hidden={state.isMask} />] }

    return result;
  }, { num: [], reg: [] });

  return (
    <div style={styles.plate}>
      <div style={styles.root}>
        {/* <div id='number' style={styles.num}>{[...makeImg.num]}</div> */}
        <div id='region' style={styles.reg}>{[...makeImg.reg]}</div>
      </div>
    </div>
  );
}