import React from 'react';
import Switchpattern from './SwitchpatternOld';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const patterns = [
    {
        ptrn: 'ZNNNzzRRR/',
        name: 'Автомобильный знак',
        shortname: 'Авто',
        sample: 'auto_3',
        desc: 'Заказть аномерной знак транспортного средства типа 1 (для автомобилей) (ГОСТ Р 50577-2018)',
        icon: 'car'
    },
    // {
    //     ptrn: 'ZNNNzzRR/',
    //     name: 'Автомобильный знак',
    //     shortname: 'Авто',
    //     sample: 'auto_3',
    //     desc: 'Заказть аномерной знак транспортного средства типа 1 (для автомобилей) (ГОСТ Р 50577-2018)',
    //     icon: 'car'
    // },
    {
        ptrn: 'ZZNNNNRR/',
        name: 'Знак для автоприцепа',
        shortname: 'Прицеп',
        sample: 'cargo_2',
        desc:  'Заказть номерной знак транспортного средства типа 2 (для прицепов) (ГОСТ Р 50577-2018)',
        icon: 'cargo'
    },
];

export default ({ clickHandle, current, ...rest }) => {
    const givenPatterns = patterns.map((pattern, key) => {
        return <Switchpattern
            pattern={pattern.ptrn}
            state={current}
            name={pattern.name}
            shortname={pattern.shortname}
            description={pattern.desc}
            sample={pattern.sample}
            onclick={clickHandle}
            key={key}
            number={rest.number}
            setNumber={rest.setNumber}
            region={rest.region}
            setRegion={rest.setRegion}
            nextStage={rest.nextStage}
            />
    });
    return (
        <Container maxWidth="md">
            <Grid container spacing={3}>
            {[...givenPatterns]}
            </Grid>
        </Container>
    );
}