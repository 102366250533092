import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Letters in SVGs
import { ReactComponent as A } from '../img/alphabet/a.svg';
import { ReactComponent as B } from '../img/alphabet/b.svg';
import { ReactComponent as E } from '../img/alphabet/e.svg';
import { ReactComponent as K } from '../img/alphabet/k.svg';
import { ReactComponent as M } from '../img/alphabet/m.svg';
import { ReactComponent as H } from '../img/alphabet/h.svg';
import { ReactComponent as O } from '../img/alphabet/o.svg';
import { ReactComponent as P } from '../img/alphabet/p.svg';
import { ReactComponent as C } from '../img/alphabet/c.svg';
import { ReactComponent as T } from '../img/alphabet/t.svg';
import { ReactComponent as Y } from '../img/alphabet/y.svg';
import { ReactComponent as X } from '../img/alphabet/x.svg';

// Main numbers in SVGs
import { ReactComponent as Num0 } from '../img/alphabet/0.svg';
import { ReactComponent as Num1 } from '../img/alphabet/1.svg';
import { ReactComponent as Num2 } from '../img/alphabet/2.svg';
import { ReactComponent as Num3 } from '../img/alphabet/3.svg';
import { ReactComponent as Num4 } from '../img/alphabet/4.svg';
import { ReactComponent as Num5 } from '../img/alphabet/5.svg';
import { ReactComponent as Num6 } from '../img/alphabet/6.svg';
import { ReactComponent as Num7 } from '../img/alphabet/7.svg';
import { ReactComponent as Num8 } from '../img/alphabet/8.svg';
import { ReactComponent as Num9 } from '../img/alphabet/9.svg';

// Region numbers in SVGs
import { ReactComponent as Reg0 } from '../img/alphabet/0r.svg';
import { ReactComponent as Reg1 } from '../img/alphabet/1r.svg';
import { ReactComponent as Reg2 } from '../img/alphabet/2r.svg';
import { ReactComponent as Reg3 } from '../img/alphabet/3r.svg';
import { ReactComponent as Reg4 } from '../img/alphabet/4r.svg';
import { ReactComponent as Reg5 } from '../img/alphabet/5r.svg';
import { ReactComponent as Reg6 } from '../img/alphabet/6r.svg';
import { ReactComponent as Reg7 } from '../img/alphabet/7r.svg';
import { ReactComponent as Reg8 } from '../img/alphabet/8r.svg';
import { ReactComponent as Reg9 } from '../img/alphabet/9r.svg';


export default ({ char, isRegion, width, hidden = false }) => {

  const useStyles = makeStyles({
    numberWrapper: {
      width: width * 0.1,
    },
    regionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: width * 0.08,
      height: '100%',
    },
    symbol: {
      opacity: 1,
    },
    symbolMask: {
      opacity: .2,
    },
    regionSymbolMask: {
      opacity: .1,
    },
  });

  // const styles = useStyles();
  const styles = useStyles();
  const wrapClass = isRegion ? styles.regionWrapper : styles.numberWrapper;
  

  const getCurrentSymbolClass = () => {
    if (hidden) {
      return isRegion ? styles.regionSymbolMask : styles.symbolMask
    }
    return styles.symbol;
  }

  const symbolClass = getCurrentSymbolClass();

  const dispatch = {
    'z': X,
    'Z': X,
    'N': isRegion ? Reg0 : Num0,
    'R': isRegion ? Reg0 : Num0,

    'а': A, 'a': A,
    'в': B, 'b': B,
    'е': E, 'e': E,
    'к': K, 'k': K,
    'м': M, 'm': M,
    'н': H, 'h': H,
    'о': O, 'o': O,
    'р': P, 'p': P, 
    'с': C, 'c': C,
    'т': T, 't': T,
    'у': Y, 'y': Y,
    'х': X, 'x': X,

    '0': isRegion ? Reg0 : Num0,
    '1': isRegion ? Reg1 : Num1,
    '2': isRegion ? Reg2 : Num2,
    '3': isRegion ? Reg3 : Num3,
    '4': isRegion ? Reg4 : Num4,
    '5': isRegion ? Reg5 : Num5,
    '6': isRegion ? Reg6 : Num6,
    '7': isRegion ? Reg7 : Num7,
    '8': isRegion ? Reg8 : Num8,
    '9': isRegion ? Reg9 : Num9,

  };
  const CurrentComponent = dispatch[char];


  return (
    <div className={wrapClass}>
      <CurrentComponent className={symbolClass} />
    </div>
  );
}