import React from 'react';
import Switcher from '../compoments/SwitcherOld';
import Headliner from '../compoments/Headliner';

export default ({ number, pattern, setNumber, setPattern, nextStage, region, setRegion }) => {
    return (
        <>
            {/* <Headliner step='Шаг 1:' headline='Выберите нужный тип пластины' /> */}
            <Switcher clickHandle={setPattern} current={pattern} number={number} setNumber={setNumber} region={region} setRegion={setRegion} nextStage={nextStage} />
        </>
    );
}
