import React from 'react';

const style = {
    root: {
        padding: '1em',
        margin: '0',
        fontWeight: 'normal',
        fontSize: '1.1em',
        color: '#ffffff'
    },
    span: {
        fontWeight: 'bold',
        fontSize: '1.3em',
        color: '#c6ff00',
    }
};

export default ({ currentState }) => {

    const createLabel = (state) => {
        if (state === 'Z') return <span style={style.span}>Префикс{<br />}номерной зоны</span>;
        if (state === 'N') return <span style={style.span}>Цифры{<br />}номерной зоны</span>;
        if (state === 'z') return <span style={style.span}>Буквы{<br />}номерной зоны</span>;
    if (state === 'R') return <span style={style.span}>Номер региона</span>;
        return <span style={style.span}>State is not defined</span>;
    }

    return (
    <div style={style.root}>Введите<br />{createLabel(currentState)}<br />в поле ниже:<br />{'\u2193'}</div> 
    );
}




